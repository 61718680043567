import FormAddressField from 'components/ui/FormAddressField'
import FormTextAreaField from 'components/ui/FormTextAreaField'
import FormDateField from 'components/ui/FormDateField'
import FormTimeField from 'components/ui/FormTimeField'
import { Form, FormSpy } from 'react-final-form'
import FormCheckBox from 'components/ui/FormCheckBox'
import FormError from 'components/ui/FormError'
import Button from 'components/ui/Button'
import parseISO from 'date-fns/parseISO'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmissionErrors } from 'final-form'
import FieldSpacer from 'components/ui/FieldSpacer'
import focusOnError from 'components/ui/focusOnError'
import useUser, { User } from 'queries/useUser'
import LoginModal from 'components/LoginModal'
import FormTextField from 'components/ui/FormTextField'
import CompleteProfileModal from 'components/CompleteProfileModal'
import { OptionGroup } from 'components/ui/DeepRadioGroup'
import FormDeepRadioGroup from 'components/ui/FormDeepRadioGroup'
import cn from 'classnames'
import FormValuesRecorder, {
  getSavedFormValues
} from 'components/FormValuesRecorder'

import MarketRules from './MarketRules'

export type FormValues = {
  eventType: string
  fullAddress: string
  vendingInstructions?: string
  date: Date
  startAt: Date
  endAt: Date
  terms: boolean
  peopleEstimated: string
  useSalesGuarantee: boolean
}

const validate = (values: Partial<FormValues>) => {
  const errors: Partial<Record<keyof FormValues, string>> = {}
  const requiredFields: (keyof FormValues)[] = [
    'eventType',
    'fullAddress',
    'date',
    'startAt',
    'endAt',
    'terms',
    'peopleEstimated'
  ]
  requiredFields.forEach((key) => {
    if (!values[key]) {
      errors[key] = 'Required'
    }
  })
  if (values.peopleEstimated && parseInt(values.peopleEstimated, 10) <= 0) {
    errors.peopleEstimated = 'Must be a positive number'
  }
  if (typeof values.useSalesGuarantee === 'undefined') {
    errors.useSalesGuarantee = 'Required'
  }
  return errors
}

export type Props = {
  onSubmit: (
    values: FormValues,
    user: User
  ) => Promise<SubmissionErrors | undefined>
  categoryOptions: OptionGroup<string>[]
  initialCateringType?: string
}

export default function EventsForm({
  onSubmit,
  categoryOptions,
  initialCateringType
}: Props) {
  const { user } = useUser({ skip: typeof window === 'undefined' })
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [showCateringSelect, setShowCateringSelection] = useState<boolean>(
    !initialCateringType
  )
  useEffect(() => {
    if (initialCateringType) {
      setShowCateringSelection(false)
    }
  }, [initialCateringType])
  const [showCompleteProfile, setShowCompleteProfile] = useState<boolean>(false)

  const initialValues = useMemo(() => {
    const savedValues = getSavedFormValues<FormValues>(
      'CATERING_EVENT_FORM_VALUES',
      {
        date: parseISO,
        startAt: parseISO,
        endAt: parseISO
      }
    )

    if (savedValues && Object.keys(savedValues).length > 0) {
      return savedValues
    }

    return {
      eventType: initialCateringType
    }
  }, [initialCateringType])

  return (
    <Form<FormValues>
      decorators={[focusOnError]}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (!user) {
          setShowLogin(true)
          return {}
        }
        if (!user.completeProfile) {
          setShowCompleteProfile(true)
          return {}
        }
        return onSubmit(values, user)
      }}
      validate={validate}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormValuesRecorder storageKey="CATERING_EVENT_FORM_VALUES" />
          <div className="shadow-md p-6 rounded-md bg-white">
            {showCateringSelect ? (
              <div>
                <FormDeepRadioGroup
                  columns={2}
                  description="Select the most applicable description of your need"
                  label="What type of a food truck service are you looking for?"
                  name="eventType"
                  optionGroups={categoryOptions}
                />
              </div>
            ) : (
              <div>
                <label
                  className={cn('block font-medium text-gray-700 mb-2')}
                  htmlFor="eventType"
                >
                  Event occasion
                </label>
                <div
                  id="eventType"
                  onClick={() => setShowCateringSelection(true)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      return setShowCateringSelection(true)
                    }
                    return null
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span className="italic underline font-medium">
                    {initialCateringType}
                  </span>{' '}
                  <span className="italic">(click to update)</span>
                </div>
              </div>
            )}
            <FieldSpacer variant="normal" />
            <FormTextField
              label="How many people do you expect to serve?"
              name="peopleEstimated"
              type="number"
            />
            <FieldSpacer variant="normal" />
            <div>
              <FormAddressField
                description="Where the event is going to take place"
                label="Address"
                name="fullAddress"
                placeholder="Start typing..."
              />
            </div>
            <FieldSpacer variant="normal" />
            <div>
              <FormTextAreaField
                description="i.e. where to park, onsite contact & mobile, bathroom location, etc."
                label="Additional instructions (optional)"
                name="vendingInstructions"
              />
            </div>
            <FieldSpacer variant="wide" />

            <div className="flex flex-wrap">
              <div className="sm:w-1/4 w-full mr-auto">
                <FormDateField label="Event Date" name="date" />
                <div className="sm:hidden block">
                  <FieldSpacer variant="tight" />
                </div>
              </div>
              <div className="sm:w-1/4 w-1/2">
                <FormTimeField
                  baseDate="date"
                  label="Start Time"
                  name="startAt"
                />
              </div>
              <div className="sm:w-1/4 w-1/2 pl-2">
                <FormTimeField baseDate="date" label="End Time" name="endAt" />
              </div>
            </div>
            <FieldSpacer variant="normal" />
            <div>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  const { fullAddress } = values
                  return <MarketRules fullAddress={fullAddress} />
                }}
              </FormSpy>
            </div>
            <FieldSpacer variant="normal" />
            <div>
              <FormCheckBox
                label="I have read and agree to the conditions"
                name="terms"
              />
            </div>
            <FormError errorKey="requestInProgress" />
            <FormError />
            <FieldSpacer variant="normal" />
            <div className="flex justify-end px-4 py-3 -m-6 bg-gray-50">
              <LoginModal
                onClose={() => setShowLogin(false)}
                onLogin={() => {
                  setShowLogin(false)
                  handleSubmit()
                }}
                open={showLogin}
                catering
              />
              {showCompleteProfile && user && (
                <CompleteProfileModal
                  onClose={() => setShowCompleteProfile(false)}
                  onComplete={() => {
                    setShowCompleteProfile(false)
                    handleSubmit()
                  }}
                  open={showCompleteProfile}
                />
              )}
              <Button
                color="primary"
                disabled={submitting}
                type="submit"
                variant="contained"
              >
                Next
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}
