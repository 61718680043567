/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import TextAreaField from 'components/ui/TextAreaField'

type TextAreaFieldProps = {
  label?: string
  description?: string
  placeholder?: string
  autoComplete?: string
  rows?: number
  type?: string
  disabled?: boolean
  className?: string
}

export type Props = TextAreaFieldProps & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerField({
  input,
  label,
  description,
  rows,
  placeholder,
  disabled,
  className,
  meta: { touched, error: syncError, submitError, submitting }
}: FieldRenderProps<string, HTMLTextAreaElement> & TextAreaFieldProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <TextAreaField
      className={className}
      description={description}
      disabled={submitting || disabled}
      error={showError ? error : null}
      label={label}
      placeholder={placeholder}
      rows={rows}
      {...input}
    />
  )
}

export default function FormTextAreaField({
  name,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<
      string,
      FieldRenderProps<string, HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
      name={name}
      render={(props) => <InnerField {...props} {...innerProps} name={name} />}
      {...fieldProps}
    />
  )
}
