import { FormSpy } from 'react-final-form'

type Props<T> = {
  onChange: (values: T) => unknown
}

export default function FormChangeSpy<T>({ onChange }: Props<T>) {
  return (
    <FormSpy<T>
      onChange={({ values }) => onChange(values)}
      subscription={{ values: true }}
    />
  )
}
