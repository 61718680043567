import { Dialog } from '@headlessui/react'
import React from 'react'
import LoginForm from 'components/LoginForm'
import { customerPhoneLogin_customerPhoneLogin_customer as Customer } from 'components/LoginForm/types/customerPhoneLogin'

type Props = {
  open: boolean
  onClose: () => void
  onLogin: (customer: Customer) => void
  catering?: boolean
  signup?: boolean
}

export default function LoginModal({
  open,
  onLogin,
  onClose,
  catering,
  signup
}: Props) {
  return (
    <Dialog
      aria-label="Sign in / Sign up"
      className="relative z-50"
      onClose={onClose}
      open={open}
    >
      <Dialog.Overlay
        className="fixed inset-0"
        style={{
          backgroundColor: 'rgba(0,0,0,0.53)'
        }}
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="inline-block w-full max-w-lg my-10 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-lg">
            <LoginForm catering={catering} onLogin={onLogin} signup={signup} />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
