/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../../global-graphql-types'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from 'utils/extendApolloHooks'
const defaultOptions = {} as const
export type CateringRequestFormCategoriesVariables = Types.Exact<{
  [key: string]: never
}>

export type CateringRequestFormCategories = {
  __typename: 'Query'
  cateringCategories: Array<{
    __typename: 'CateringCategory'
    id: number
    name: string
    mainPage: boolean
    parentCategory: { __typename: 'CateringCategory'; id: number } | null
    childCategories: Array<{
      __typename: 'CateringCategory'
      id: number
      name: string
    }>
  }>
}

export const CateringRequestFormCategoriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CateringRequestFormCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cateringCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mainPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useCateringRequestFormCategories__
 *
 * To run a query within a React component, call `useCateringRequestFormCategories` and pass it any options that fit your needs.
 * When your component renders, `useCateringRequestFormCategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCateringRequestFormCategories({
 *   variables: {
 *   },
 * });
 */
export function useCateringRequestFormCategories(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CateringRequestFormCategories,
    CateringRequestFormCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CateringRequestFormCategories,
    CateringRequestFormCategoriesVariables
  >(CateringRequestFormCategoriesDocument, options)
}
export function useCateringRequestFormCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CateringRequestFormCategories,
    CateringRequestFormCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CateringRequestFormCategories,
    CateringRequestFormCategoriesVariables
  >(CateringRequestFormCategoriesDocument, options)
}
export type CateringRequestFormCategoriesHookResult = ReturnType<
  typeof useCateringRequestFormCategories
>
export type CateringRequestFormCategoriesLazyQueryHookResult = ReturnType<
  typeof useCateringRequestFormCategoriesLazyQuery
>
export type CateringRequestFormCategoriesQueryResult = Apollo.QueryResult<
  CateringRequestFormCategories,
  CateringRequestFormCategoriesVariables
>
