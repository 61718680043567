import React from 'react'
import { gql, useQuery } from '@apollo/client'
import FormRadioGroup from 'components/ui/FormRadioGroup'

import {
  marketByAddress,
  marketByAddressVariables
} from './types/marketByAddress'

type Props = {
  fullAddress?: string
}

const QUERY = gql`
  query marketByAddress($fullAddress: String!) {
    marketByAddress(fullAddress: $fullAddress) {
      id
      eventsReward {
        formatted
        cents
      }
      eventsMinOrdersAmount {
        cents
        formatted
      }
      eventsSalesGuaranteeAmount {
        cents
        formatted
      }
    }
  }
`

export default function MarketRules({ fullAddress }: Props) {
  const { data } = useQuery<marketByAddress, marketByAddressVariables>(QUERY, {
    skip: !fullAddress || !fullAddress.length,
    variables: {
      fullAddress: fullAddress || ''
    }
  })
  const market = data?.marketByAddress

  if (!market) return null

  const guaranteeOptions = [
    {
      label: `Minimum Sales Guarantee: customers can pre-order meals up to 60 days in advance. If the ${market.eventsMinOrdersAmount.formatted} pre-order sales minimum is not met within 48 hours of the shift start time, the truck may cancel the event and the pre-orders placed.`,
      value: false
    },
    {
      label: `${market.eventsSalesGuaranteeAmount.formatted} Minimum Guarantee: If the food truck does not make the ${market.eventsSalesGuaranteeAmount.formatted} sales minimum, you will need to cover the difference.`,
      value: true
    }
  ]

  return (
    <div className="prose-sm">
      {market.eventsMinOrdersAmount.cents > 0 && (
        <>
          <p className="font-bold">
            You are requesting service from food trucks in your region. A food
            truck may book your event and be confirmed automatically.
          </p>
          <p>
            Food trucks in your region require a pre-order sales minimum of{' '}
            {market.eventsMinOrdersAmount.formatted} or a minimum guarantee of{' '}
            {market.eventsSalesGuaranteeAmount.formatted}. For the{' '}
            {market.eventsMinOrdersAmount.formatted} pre-order sales minimum,
            customers will have the ability to pre-order their meals up to 60
            days before the scheduled shift in hopes of meeting the minimum
            requirement.
          </p>
          <p>
            If the {market.eventsMinOrdersAmount.formatted} pre-order sales
            minimum required amount is not met within 48 hours of the shift
            start time, the truck will have the option to cancel the shift and
            all of the pre-orders placed.
          </p>
        </>
      )}
      {market.eventsReward.cents > 0 && (
        <p>
          As a bonus, the organizer filling out this application will get{' '}
          {market.eventsReward.formatted} in credits to use towards their order.
        </p>
      )}

      <FormRadioGroup
        label="Preferred sales option"
        name="useSalesGuarantee"
        options={guaranteeOptions}
      />
    </div>
  )
}
