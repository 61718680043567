import FormChangeSpy from 'components/FormChangeSpy'
import { useMemo } from 'react'
import debounce from 'lodash/debounce'

type Deserializers<T> = Partial<Record<keyof T, (v: string) => unknown>>
type Props = {
  storageKey: string
}

export function getSavedFormValues<T>(
  storageKey: string,
  deserializers: Deserializers<T> = {}
): Partial<T> | null {
  if (typeof sessionStorage === 'undefined') return null

  const text = sessionStorage.getItem(storageKey)

  if (!text) return null

  let object: Partial<T>
  try {
    object = JSON.parse(text)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return null
  }

  if (!object) return null

  Object.keys(object).forEach((str) => {
    const key = str as keyof T
    if (deserializers[key] && typeof object[key] !== 'undefined') {
      const value = object[key] as T[keyof T]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      object[key] = deserializers[key]!(value as any) as T[keyof T]
    }
  })
  return object
}

export function clearSavedFormValues(storageKey: string) {
  if (typeof sessionStorage === 'undefined') return

  sessionStorage.removeItem(storageKey)
}

export default function FormValuesRecorder<T extends {}>({
  storageKey
}: Props) {
  const saveWithDebounce = useMemo(
    () =>
      debounce(
        (values: T) => {
          if (typeof sessionStorage === 'undefined') return

          sessionStorage.setItem(storageKey, JSON.stringify(values))
        },
        100,
        { maxWait: 500 }
      ),
    [storageKey]
  )

  return <FormChangeSpy onChange={saveWithDebounce} />
}
