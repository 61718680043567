/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import DeepRadioGroup, {
  OptionGroup,
  PossibleValue
} from 'components/ui/DeepRadioGroup'

type RadioGroupProps<T extends PossibleValue> = {
  description?: string
  label: string
  optionGroups: OptionGroup<T>[]
  columns?: number
  questionId?: string
}

export type Props<T extends PossibleValue> = RadioGroupProps<T> & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerFormDeepRadioGroup<T extends PossibleValue>({
  description,
  label,
  optionGroups,
  input,
  name,
  columns,
  questionId = name,
  meta: { touched, error: syncError, submitError }
}: FieldRenderProps<T, HTMLInputElement> & RadioGroupProps<T>) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <DeepRadioGroup<T>
      columns={columns}
      description={description}
      error={showError ? error : null}
      label={label}
      name={name}
      onChange={(value) => {
        input.onChange(value)
        input.onBlur()
      }}
      optionGroups={optionGroups}
      questionId={questionId}
      value={input.value}
    />
  )
}

export default function FormDeepRadioGroup<T extends PossibleValue = string>({
  name,
  fieldProps = {},
  ...innerProps
}: Props<T>) {
  return (
    <Field<T, FieldRenderProps<T, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => (
        <InnerFormDeepRadioGroup<T> {...props} {...innerProps} name={name} />
      )}
      {...fieldProps}
    />
  )
}
