/* eslint-disable react/no-array-index-key */
import React from 'react'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import groupBy from 'lodash/groupBy'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export type PossibleValue = string | number | object | boolean

export type OptionGroup<T extends PossibleValue> = {
  label: React.ReactElement | string
  value: T
  options: Option<T>[]
}

export type Option<T extends PossibleValue> = {
  label: React.ReactElement | string
  value: T
}

export type Props<T extends PossibleValue = string> = {
  label?: string
  description?: string
  name: string
  optionGroups: OptionGroup<T>[]
  value: T | null
  onChange: (value: T) => void
  error?: string | null
  columns?: number
  questionId?: string
}

export default function DeepRadioGroup<T extends PossibleValue = string>({
  label,
  description,
  error,
  optionGroups,
  value,
  name,
  onChange,
  columns,
  questionId = 'deep-radio-group'
}: Props<T>) {
  return (
    <div
      aria-describedby={`description-${questionId}`}
      aria-labelledby={`label-${questionId}`}
      className="w-full relative"
      role="radiogroup"
    >
      <div className="mb-2">
        {error && (
          <div className="absolute inset-y-0 right-0 top-0 pr-3 pointer-events-none">
            <ExclamationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-500"
            />
          </div>
        )}
        <p
          className="block text-base font-medium text-gray-700"
          id={`label-${questionId}`}
        >
          {label}
        </p>
        {description && (
          <div>
            <p className="text-sm italic text-gray-500 font-light my-1">
              {description}
            </p>
          </div>
        )}
        {error && (
          <div>
            <p className="text-sm italic text-red-500 font-light mt-1">
              {error}
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col lg:flex-row">
        {Object.values(
          groupBy(
            optionGroups,
            (group: OptionGroup<T>) =>
              optionGroups.indexOf(group) % (columns ?? 1)
          )
        ).map((optionGroupsGroup: OptionGroup<T>[], groupIndex) => (
          <div key={groupIndex} className="flex flex-col gap-2">
            {optionGroupsGroup.map((group, index) => {
              const parentChecked = isEqual(group.value, value)
              const childrenChecked = !!group.options.find((o) =>
                isEqual(o.value, value)
              )
              const showChildren =
                group.options.length > 0 && (childrenChecked || parentChecked)
              return (
                <div
                  key={`${group.label.toString()}-${index}`}
                  className="flex flex-col"
                >
                  <label className="inline-flex items-center">
                    <input
                      aria-checked={parentChecked}
                      checked={parentChecked}
                      className={cn(
                        'rounded-full border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 scroll-offset text-orange-500 focus:border-orange-500 focus:ring-orange-500'
                      )}
                      name={name}
                      onChange={() => onChange(group.value)}
                      type="radio"
                    />
                    <span className="ml-3 text-sm text-gray-700">
                      {group.label}
                    </span>
                  </label>
                  <div
                    className={cn(
                      'pl-8 flex flex-col overflow-y-hidden gap-2 mt-2'
                    )}
                    style={{ height: showChildren ? 'auto' : 0 }}
                  >
                    {group.options.map((option) => {
                      const checked = isEqual(option.value, value)
                      return (
                        <label
                          key={option.value.toString()}
                          className="inline-flex items-center"
                        >
                          <input
                            aria-checked={checked}
                            checked={checked}
                            className={cn(
                              'rounded-full border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 scroll-offset text-orange-500 focus:border-orange-500 focus:ring-orange-500'
                            )}
                            name={name}
                            onChange={() => onChange(option.value)}
                            type="radio"
                          />
                          <span className="ml-3 text-sm text-gray-700">
                            {option.label}
                          </span>
                        </label>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
