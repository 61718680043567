import queryString from 'querystring'

import { NextPage } from 'next'
import { withApollo } from 'components/withApollo'
import getStaticApolloProps from 'lib/getStaticApolloProps'
import PageLayout from 'components/pages/PageLayout'
import React, { useMemo } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useRouter } from 'next/router'
import toApiDateTime from 'utils/toApiDateTime'
import EventsForm, {
  FormValues
} from 'components/pages/EventsFormPage/EventsForm'
import Title from 'components/ui/Title'
import { InformationCircleIcon, ArrowLeftIcon } from '@heroicons/react/outline'
import PageMeta from 'components/PageMeta'
import Link from 'next/link'
import getMutationErrors, {
  getMutationErrorData
} from 'utils/getMutationErrors'
import { useCateringRequestFormCategories } from 'components/pages/CateringRequestFormPage/types/CateringRequestFormCategories'
import { OptionGroup } from 'components/ui/DeepRadioGroup'
import sortBy from 'lodash/sortBy'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { clearSavedFormValues } from 'components/FormValuesRecorder'
import { logEvent, setAdsUserData } from 'utils/analytics'
import ChatWidget from 'components/ChatWidget'

import { User } from '../../../queries/useUser'

import {
  eventRequestCreate,
  eventRequestCreateVariables
} from './types/eventRequestCreate'

type Props = {}

const MUTATION = gql`
  mutation eventRequestCreate(
    $fullAddress: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $eventType: String!
    $vendingInstructions: String
    $peopleEstimated: Int!
    $useSalesGuarantee: Boolean!
    $creationNote: String
  ) {
    eventsRequestCreate(
      fullAddress: $fullAddress
      startAt: $startAt
      endAt: $endAt
      eventType: $eventType
      vendingInstructions: $vendingInstructions
      peopleEstimated: $peopleEstimated
      useSalesGuarantee: $useSalesGuarantee
      creationNote: $creationNote
    ) {
      eventsRequest {
        id
      }
      errors {
        fullMessage
        key
        message
        data
      }
    }
  }
`

function EventsFormPage() {
  const client = useApolloClient()
  const router = useRouter()

  const {
    event: initialCateringType,
    truckId: preferredTruckId,
    note: urlNote
  } = (router.query || {}) as {
    event?: string
    truckId?: string
    note?: string
  }

  const { data: categoriesData } = useCateringRequestFormCategories({
    fetchPolicy: 'cache-first'
  })

  const categoryOptions: OptionGroup<string>[] = useMemo(() => {
    if (!categoriesData?.cateringCategories) return []

    return sortBy(
      categoriesData.cateringCategories.filter(
        (c) => !c.mainPage && !c.parentCategory
      ),
      'name'
    ).map(
      (c): OptionGroup<string> => {
        const options = [
          ...sortBy(c.childCategories, 'name').map((child) => ({
            label: child.name,
            value: child.name
          }))
        ]
        if (c.childCategories.length > 0) {
          options.push({
            label: `${c.name} - Other`,
            value: `${c.name} - Other`
          })
        }
        return {
          label: c.name,
          value: c.name,
          options
        }
      }
    )
  }, [categoriesData])

  const onSubmit = async (values: FormValues, user: User) => {
    const response = await client.mutate<
      eventRequestCreate,
      eventRequestCreateVariables
    >({
      mutation: MUTATION,
      variables: {
        eventType: values.eventType,
        startAt: toApiDateTime(values.startAt),
        endAt: toApiDateTime(values.endAt),
        fullAddress: values.fullAddress,
        vendingInstructions: values.vendingInstructions,
        peopleEstimated: parseInt(values.peopleEstimated as string, 10),
        useSalesGuarantee: values.useSalesGuarantee,
        creationNote: urlNote
      }
    })

    const errors = getMutationErrors(response, {
      ...values,
      requestInProgress: true
    })
    if (errors) {
      if (errors.requestInProgress) {
        const errorData = getMutationErrorData<{ event_request_id: number }>(
          response,
          'requestInProgress'
        )
        errors.requestInProgress = (
          <>
            You already have an event request in progress. If you want to submit
            another request please cancel{' '}
            <Link
              className="underline"
              href={`/events/requests/${errorData!.event_request_id}`}
            >
              the existing request
            </Link>{' '}
            or contact support{' '}
            <a className="underline" href="sms:+18333780040">
              (833) 378-0040
            </a>
          </>
        )
      }
      return errors
    }

    await router.push({
      pathname: `/events/requests/${
        response.data!.eventsRequestCreate!.eventsRequest!.id
      }/trucks`,
      query: preferredTruckId
        ? {
            truckId: preferredTruckId
          }
        : {}
    })

    await setAdsUserData(user)

    logEvent('purchase', {
      transaction_id: response.data!.eventsRequestCreate!.eventsRequest!.id,
      currency: 'USD',
      value: 100,
      purchase_type: 'event_request',
      items: [
        {
          item_id: 'event_request',
          item_name: 'Event Request'
        }
      ]
    })

    // event_request_ads
    logEvent('conversion', {
      transaction_id: response.data!.eventsRequestCreate!.eventsRequest!.id,
      currency: 'USD',
      value: 100,
      send_to: 'AW-768772864/Z2czCLKCoIkZEICWyu4C',
      items: [
        {
          item_id: 'event_request',
          item_name: 'Event Request'
        }
      ]
    })

    clearSavedFormValues('CATERING_EVENT_FORM_VALUES')

    return undefined
  }
  return (
    <>
      <ChatWidget />

      <PageMeta
        canonicalPath="/events/form"
        title="Best Food Trucks | Hire a food truck"
      />
      <PageLayout gray hideFooter staticPage>
        <div className="flex flex-col max-w-screen-sm pb-40 md:pb-16 relative">
          <div className="m-4 md:m-0">
            <div className="relative lg:mt-16 xl:mt-10 mt-14">
              <div className="absolute xl:top-0 -top-14 xl:right-full w-max pr-10">
                <Link
                  href={`/hire-food-truck?${queryString.stringify(
                    pickBy(
                      {
                        truckId: preferredTruckId,
                        event: initialCateringType,
                        note: urlNote
                      },
                      identity
                    )
                  )}`}
                >
                  <div className="flex flex-row group items-center px-2 py-1 md:px-4 md:py-2 bg-gray-100 rounded-md transition-all duration-200">
                    <ArrowLeftIcon className="text-gray-400 w-4 h-4 mr-2 group-hover:text-gray-500" />
                    <p className="text-sm text-gray-500 group-hover:text-gray-600">
                      Back to event selection
                    </p>
                  </div>
                </Link>
              </div>

              <Title variant="pageTitle">Hire a food truck</Title>
            </div>

            <div>
              <div className="flex flex-row my-10">
                <div>
                  <InformationCircleIcon className="text-gray-500 w-6 h-6" />
                </div>

                <p className="italic text-justify text-gray-700 px-2">
                  You can reach out to trucks directly with this form. Be sure
                  to choose trucks that you want to serve at your event. Once a
                  truck has accepted the request, they’ll turn on orders and
                  you’ll receive the order link.
                  <br />
                  <br />
                  If you need help scheduling trucks for catering or more
                  complicated events, click{' '}
                  <Link
                    className="underline font-medium"
                    href="/hire-food-truck"
                  >
                    here
                  </Link>
                  . If you&apos;d like to discuss your needs with one of our
                  booking specialists directly, you can send an email to:{' '}
                  <a
                    className="underline font-medium"
                    href="mailto:sales@bestfoodtrucks.com"
                  >
                    sales@bestfoodtrucks.com
                  </a>
                </p>
              </div>
              <EventsForm
                categoryOptions={categoryOptions}
                initialCateringType={initialCateringType}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default withApollo({ ssr: false })(EventsFormPage as NextPage)

export const getStaticProps = getStaticApolloProps<Props>(EventsFormPage)
